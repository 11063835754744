









































































































import SeletorDeCfop from '@/components/fiscal/SeletorDeCfop.vue'
import SeletorDeCst from '@/components/fiscal/SeletorDeCst.vue'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import { InfoNotaComplementar } from '@/models'
import { ProdutoService } from '@/services'
import { maiorOuIgualAZero, maiorQueZero, obrigatorio, validarCampoObrigatorio } from '@/shareds/regras-de-form'
import { criarItemDaVenda } from '@/shareds/venda-shareds'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import type { NotaFiscalServiceAdapter } from '@/usecases'
import { Vue, Component, PropSync, Watch, Ref, Prop } from 'vue-property-decorator'
import { Inject } from 'inversify-props'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component({
	components: {
		SeletorDeCfop,
		SeletorDeCst,
		CampoDecimal,
	},
})
export default class NotaComplementarView extends Vue {
	@PropSync('mostra', { type: Boolean, default: true }) syncMostra!: boolean
	@Prop() vendaId!: string
	@Ref() form!: HTMLFormElement

	obrigatorio = obrigatorio
	maiorQueZero = maiorQueZero
	maiorOuIgualAZero = maiorOuIgualAZero
	validarCampoObrigatorio = validarCampoObrigatorio

	@Inject('NotaFiscalServiceAdapter')
	private notaFiscalService!: NotaFiscalServiceAdapter

	findProduto = new ProdutoService()

	infoNotaComplementar: InfoNotaComplementar = criarInfoNotaComplementar()
	infoAdicionais = ''
	loading = false
	carregando = false

	@Watch('syncMostra')
	async onMostraChange() {
		try{
			this.loading = true
			if (this.syncMostra && this.vendaId) {
				const infoNotaComplementarNota = await this.notaFiscalService.findInfoNotaComplementarByVendaId(this.vendaId)
				
				this.infoNotaComplementar = {
					...infoNotaComplementarNota,
				}
			}
			this.loading = false
		} catch (error) {
			AlertModule.setError(error)
			this.loading = false
		}
		
	}

	@Watch('infoNotaComplementar', { deep: true })
	calcularImpostoVIcms() {
		if(this.infoNotaComplementar.valorBcICMS === null || this.infoNotaComplementar.percentualICMS === null) return

		this.infoNotaComplementar.valorICMS = this.infoNotaComplementar.valorBcICMS * this.infoNotaComplementar.percentualICMS / 100
	}

	onlyNumbers(event) {
		if (!/^\d$/.test(event.key)) {
			event.preventDefault();
		}
	}

	async concluir() {
		if (!this.form.validate()) return

		try {
			this.carregando = true

			if(VendaModule.emissaoEntradaAtual) {
				const filtros = 'NFS.50.131223.2782050.1'
				const produtoNotaComplementar = await this.findProduto.encontrarProdutoPorIdentificador(filtros)
				VendaModule.emissaoEntradaAtual.itens.push(criarItemDaVenda(produtoNotaComplementar, 1))
				VendaModule.emissaoEntradaAtual.infoAdicionais = this.infoAdicionais
				VendaModule.emissaoEntradaAtual.tipoDeTransacao = 'Complementar'
			}

			this.$emit('concluir', this.infoNotaComplementar)
			this.form.resetValidation()
			this.infoNotaComplementar = criarInfoNotaComplementar()
			this.syncMostra = false
			this.carregando = false
		} catch (error) {
			this.carregando = false
			AlertModule.setError(error)
		}

	}

	cancelar() {
		this.form.resetValidation()
		this.infoNotaComplementar = criarInfoNotaComplementar()
		this.$emit('update:mostra', false)
	}
}

function criarInfoNotaComplementar(): InfoNotaComplementar {
	return {
		cstICMS: null,
		cfop:  null,
		origemICMS: 0,
		modBcICMS: 0,
		valorBcICMS: 0,
		percentualICMS: 0,
		valorICMS: 0,
		chnfeReferencia: '',
		infoAdicionais: '',
	}
}
