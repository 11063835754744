











































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'

@Component({
	inheritAttrs: false,
})
export default class Confirmacao extends Vue {
	@Prop({ default: 'Confirma?' }) titulo!: string
	@Prop() subtitulo?: string
	@Prop() opcaoPadrao?: 'Não' | 'Sim'
	@Prop({ default: 'Não' }) opcaoA!: string
	@Prop({ default: 'Sim' }) opcaoB!: string
	@Prop({ default: '300' }) width!: string
	@Ref() botaoOpcaoA!: { $el: HTMLButtonElement }
	@Ref() botaoOpcaoB!: { $el: HTMLButtonElement }
	resolveDialogo: ((value: boolean) => void) | null = null
	mostra: boolean | null = null

	async mostrar(): Promise<boolean> {
		this.mostra = true
		return new Promise(resolve => this.resolveDialogo = resolve)
	}

	async mostrarComParametros(titulo: string, subtitulo: string): Promise<boolean> {
		this.titulo = titulo
		this.subtitulo = subtitulo
		this.mostra = true
		return new Promise(resolve => this.resolveDialogo = resolve)
	} 

	selecionaOpcaoA() {
		this.resolveDialogo && this.resolveDialogo(false)
		this.$emit('negar', this.opcaoA)
		this.mostra = false
	}

	selecionaOpcaoB() {
		this.resolveDialogo && this.resolveDialogo(true)
		this.$emit('confirmar', this.opcaoB)
		this.mostra = false
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) {
			this.resolveDialogo && this.resolveDialogo(false)
			return
		}
		setTimeout(() => {
			switch (this.opcaoPadrao) {
				case 'Não':
					return this.botaoOpcaoA.$el.focus()
				case 'Sim':
					return this.botaoOpcaoB.$el.focus()
				default: 
					return null
			}
		}, 125)
	}
}
