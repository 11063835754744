








































































































































































































































import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import DialogoDeEdicaoDeTitulo from './DialogoDeEdicaoDeTitulo.vue'
import DatePicker from '@/components/ui/DatePicker.vue'
import {
	FiltroDeTitulos,	
	Page,
	Pageable,
	Pessoa,
	SituacaoDoTitulo,
	TipoDeCliente,
	TipoDePessoa,
	Titulo,
	FormaDePagamento,
} from '@/models'
import type { FluxoDoTitulo } from '@/models'
import SeletorDeTipoDeCliente from '@/components/tabeladepreco/SeletorDeTipoDeCliente.vue'
import { CancelToken, CancelTokenSource } from 'axios'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import SelecaoDePdv from '@/components/venda/SelecaoDePdv.vue'
import { DeleteTituloUseCase, SaveTituloUseCase } from '@/usecases/titulos'
import { FindTituloUseCase } from '@/usecases/titulos/FindTituloUseCase'
import { DataOptions } from 'vuetify'
import SeletorDePessoa from '@/components/cliente/SeletorDePessoa.vue'
import { dateToPtBrFormat } from '@/shareds/date/date-utils'
import CheckboxButton from '@/components/ui/CheckboxButton.vue'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import SeletorDeCliente from '../clientes/SeletorDeCliente.vue'
import SeletorDeFornecedorComBusca from '@/components/fornecedor/SeletorDeFornecedorComBusca.vue'

const FILTRO_DE_TITULOS = 'FILTRO_DE_TITULOS'

@Component({
	components: {
		DatePicker,
		SelecaoDePdv,
		SeletorDePessoa,
		SeletorDeTipoDeCliente,
		DataTableDeCrudPaginado,
		DialogoDeEdicaoDeTitulo,
		CheckboxButton,
		SeletorDeLojasDoUsuario,
		SeletorDeCliente,
		SeletorDeFornecedorComBusca,
	},
})
export default class TelaDeTitulos extends Vue {
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeTitulo
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado

	@Prop({ type: String, required: true }) fluxo!: FluxoDoTitulo

	deleteTituloUseCase = new DeleteTituloUseCase()
	findTituloUseCase = new FindTituloUseCase()
	saveTituloUseCase = new SaveTituloUseCase()

	cancelToken: CancelTokenSource | null = null
	carregando = false

	numeroDocumento = undefined

	opcoesDeFormaDePagamento: FormaDePagamento[] = [
		'Dinheiro',
		'Cartão',
		'Voucher',
		'Crediário',
		'Link',
		'TEF Crédito',
		'TEF Débito',
		'TEF Pix',
		'Outros',
	]

	opcoesDeSituacaoDeTitulo: SituacaoDoTitulo[] = [
		'Aberto',
		'Pago',
		'Cancelado',
		'Estornado',
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	titulos: Titulo[] = []
	titulosAlterados: Titulo[] = []
	titulosSelecionados: TelaDeTitulos['titulosFormatados'] = []
	todosForamSelecionados = false
	buscando = false

	filtros: FormDeFiltroDeTitulos = localStorage[FILTRO_DE_TITULOS]
		? JSON.parse(localStorage[FILTRO_DE_TITULOS]) 
		: this.obterFiltrosDeTitulosIniciais()

	headers = [
		{ text: 'Loja', value: 'loja' },
		{ text: 'Série', value: 'serie' },
		{ text: 'Número', value: 'numero' },
		{ text: 'Parcela', value: 'sequencia' },
		{ text: 'Vencimento', value: 'dataDeVencimento' },
		{ text: 'Descrição', value: 'descricao' },
		{ text: 'Cliente', value: 'pessoa' },
		{ text: 'Valor', value: 'valor' },
		{ text: 'Valor Atualizado', value: 'valorAtualizado', sortable: false },
		{ text: 'Forma de pagamento', value: 'formaDePagamento' },
	]
	
	mounted() {
		this.aplicarFiltros();
	}

	get titulosFormatados() {
		return this.titulos.map(titulo => ({
			...titulo,
			dataDeVencimento: dateToPtBrFormat(titulo.dataDeVencimento),
			valor:
				titulo?.valor?.toLocaleString('pt-br', {
					style: 'currency',
					currency: 'BRL',
				}) || '',
			pessoa: titulo.pessoa?.razaoSocialOuNome || '',
			loja: titulo.loja?.nomeFantasia || '',
			formaDePagamento: titulo.formaDePagamento
				? this.displayPagamento(titulo.formaDePagamento)
				: '',
			valorAtualizado:
				titulo?.valorAtualizado?.toLocaleString('pt-br', {
					style: 'currency',
					currency: 'BRL',
				}) || '',
		}))
	}

	displayPagamento(formaDePagamento: string) {
		let formaFormatada
		if (formaDePagamento == 'CARTAO') formaFormatada = 'Cartão'
		if (formaDePagamento == 'DINHEIRO') formaFormatada = 'Dinheiro'
		if (formaDePagamento == 'VOUCHER') formaFormatada = 'Voucher'
		if (formaDePagamento == 'OUTROS') formaFormatada = 'Outros'
		if (formaDePagamento == 'TEF CREDITO') formaFormatada = 'TEF Crédito'
		if (formaDePagamento == 'TEF DEBITO') formaFormatada = 'TEF Débito'
		if (formaDePagamento == 'CREDIARIO') formaFormatada = 'Crediário'
		return formaFormatada
	}

	async baixarTitulo() {
		const titulosId = this.titulosSelecionados.map(
			itemDalista => itemDalista.id,
		)
		if (!this.titulosSelecionados.length) return

		try {
			this.carregando = true
			this.titulosAlterados = await this.saveTituloUseCase.updateTitulos(
				titulosId,
				'Pago',
			)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
			AlertModule.setSuccess('Baixa de recebíveis realizada com sucesso!')
		}
		if (this.titulosAlterados.length) {
			for (let i = 0; i <= this.titulos.length - 1; i++) {
				this.titulosAlterados.forEach(tituloAlterado => {
					if (tituloAlterado.id == this.titulos[i].id)
						this.titulos.splice(i, 1, tituloAlterado)
				})
			}
		}
	}

	selecionarTodos() {
		this.titulosSelecionados = this.titulosFormatados
		this.todosForamSelecionados = true
	}

	limparSelecao() {
		this.titulosSelecionados = []
		this.todosForamSelecionados = false
	}

	@Watch('titulosSelecionados', { immediate: true })
	onChangeTitulosSelecionados() {
		if (this.titulosSelecionados.length !== this.titulosFormatados.length) {
			this.todosForamSelecionados = false
		}
	}

	abrirDialogoDeCriacaoDeTitulo() {
		this.dialogoDeEdicao.mostrarDialogo(this.fluxo, 'criar')
	}

	abrirDialogoDeEdicaoDeTitulo(indice: number) {
		this.dialogoDeEdicao.mostrarDialogo(this.titulos[indice], 'editar')
	}

	get tokenDoUsuario() {
		return UserLoginStore.token
	}

	obterFiltrosDeTitulosIniciais(): FormDeFiltroDeTitulos {
		return {
			pessoa: null,
			tipoDeCliente: null,
			dataInicioPeriodo: null,
			dataFimPeriodo: null,
			situacaoDoTitulo: null,
			formaDePagamento: null,
			loja: null,
			numeroDocumento: null,
			dataInicioPagamento: null,
			dataFimPagamento: null,
		}
	}

	get tipoDePessoa(): TipoDePessoa {
		return this.fluxo == 'Entrada' ? 'Cliente' : 'Fornecedor'
	}

	@Watch('tipoDePessoa')
	async onChangePessoa() {
		this.dataTablePaginado.reiniciar(null)
	}

	get filtrosFormatados(): FiltroDeTitulos {
		return {
			tipoDeCliente: this.filtros.tipoDeCliente?.id || undefined,
			pessoa: this.filtros.pessoa?.id || undefined,
			dataInicioPeriodo: this.filtros.dataInicioPeriodo || undefined,
			dataFimPeriodo: this.filtros.dataFimPeriodo || undefined,
			situacaoDoTitulo: this.filtros.situacaoDoTitulo || undefined,
			formaDePagamento: this.filtros.formaDePagamento || undefined,
			loja: this.filtros.loja || undefined,
			numeroDocumento: this.filtros.numeroDocumento || undefined,
		}
	}

	aplicarFiltros() {
		localStorage[FILTRO_DE_TITULOS] = JSON.stringify(this.filtros)
		this.dataTablePaginado.reiniciar(this.filtrosFormatados)
	}

	limparFiltros() {
		this.filtros = this.obterFiltrosDeTitulosIniciais()
		this.aplicarFiltros()
	}

	insereTitulo(titulos: Array<Titulo>) {
		this.titulos = this.titulos.concat(titulos)
		this.dataTablePaginado.reiniciar(null)
	}

	async buscarPagina(
		paginavel: Pageable,
		parametros: {
			nomeDoCliente?: string
			tipoDeCliente?: string
			loja?: string
			numeroDocumento?: string
		},
		cancelToken: CancelToken,
	): Promise<Page<Titulo>> {
		const pagina = await this.findTituloUseCase.find(
			{
				...paginavel,
				...parametros,
				fluxo: this.fluxo,
				token: this.tokenDoUsuario,
			},
			{
				cancelToken,
			},
		)
		this.titulos = pagina.content
		return pagina
	}

	get itens() {
		return this.titulos
	}

	async removerTitulo(indice: number) {
		const titulo = this.itens[indice]
		if (!titulo) return

		try {
			await this.deleteTituloUseCase.execute(titulo.id)
			this.titulos = this.titulos.filter(({ id }) => id !== titulo.id)
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}
}

export interface FormDeFiltroDeTitulos {
	pessoa: null | Pessoa
	tipoDeCliente: TipoDeCliente | null
	dataInicioPeriodo: string | null
	dataFimPeriodo: string | null
	situacaoDoTitulo: SituacaoDoTitulo | null
	formaDePagamento: string | null
	loja: string | null
	numeroDocumento: string | null
	dataInicioPagamento: string | null
	dataFimPagamento: string | null
}
