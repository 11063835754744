import { render, staticRenderFns } from "./ListagemDeCartasDeCorrecao.vue?vue&type=template&id=ab3e6cee&scoped=true&"
import script from "./ListagemDeCartasDeCorrecao.vue?vue&type=script&lang=ts&"
export * from "./ListagemDeCartasDeCorrecao.vue?vue&type=script&lang=ts&"
import style0 from "./ListagemDeCartasDeCorrecao.vue?vue&type=style&index=0&id=ab3e6cee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab3e6cee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardTitle,VContainer,VDialog,VIcon,VTooltip})
