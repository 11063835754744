import { FiltroDeTitulos, Titulo } from "@/models/financeiro/Titulo";

export function limparFiltroDosCamposDeTitulos(): FiltroDeTitulos {
	return {
		pessoa: '',
		tipoDeCliente: '',
		dataInicioPeriodo: '',
		dataFimPeriodo: '',
		formaDePagamento: '',
		numeroDocumento: '',
		dataInicioPagamento: '',
		dataFimPagamento: '',
	}
}

export function calcularDataAposCarencia(titulo: Titulo, dataAposCarencia: Date): Date {	
	const data = new Date(dataAposCarencia.getFullYear(), dataAposCarencia.getMonth(), dataAposCarencia.getDate())

	data.setDate(data.getDate() + (titulo.diasDeCarencia || 0))

	const diaDaSemana = data.getDay()
	if (diaDaSemana === 6) { // Sábado
		data.setDate(data.getDate() + 2)
	} else if (diaDaSemana === 0) { // Domingo
		data.setDate(data.getDate() + 1)
	}

	data.setDate(data.getDate())

	return new Date(data.getFullYear(), data.getMonth(), data.getDate())
}

