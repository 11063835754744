
































































































































































































































import SeletorDeCfop from '@/components/fiscal/SeletorDeCfop.vue'
import { CapaDaNotaParaCartaDeCorrecao, Venda, VendaComReferencias } from '@/models'
import { CartaDeCorrecao, ItemDaCartaDeCorrecao } from '@/models/fiscal/CartaDeCorrecao'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { maiorOuIgualAZero } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { CartaDeCorrecaoUseCase, FindCapaDaNotaUseCase } from '@/usecases'
import { Vue, Component, Ref } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'

@Component({
	components: {
		SeletorDeCfop,
	},
	directives: {
		mask,
	},
})
export default class DialogoDeCriacaoDaCartaDeCorrecao extends Vue {
	@Ref() form!: HTMLFormElement

	venda: VendaComReferencias | null = null
	nota: CapaDaNotaParaCartaDeCorrecao | null = null
	
	cartaDeCorrecaoUseCase = new CartaDeCorrecaoUseCase()
	findCapaDaNotaUseCase = new FindCapaDaNotaUseCase()

	cartaDeCorrecao = criarNovaCartaDeCorrecao()
	
	maiorOuIgualAZero = maiorOuIgualAZero
	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	etapa = 1
	mostra = false
	salvando = false

	esconder() {
		this.mostra = false
		this.salvando = false
		this.cartaDeCorrecao = criarNovaCartaDeCorrecao()
	}

	async mostrar(venda: Venda | null) {
		this.etapa = 1
		const notaFiltrada = venda?.notas.find(nota => nota.cstat === '100') || null
		if(!notaFiltrada) throw new Error('Nota válida não encontrada')
		this.nota = await this.findCapaDaNotaUseCase.findByChNFe(notaFiltrada?.chNFe)
	
		if(!this.nota) throw new Error('Nota válida não encontrada')

		this.cartaDeCorrecao.enderecoDest = this.nota.lgrDest
		this.cartaDeCorrecao.nroDestAtual = this.nota.nroDest
		this.cartaDeCorrecao.nroDestCorrigido = this.nota.nroDest
		this.cartaDeCorrecao.complementoDestAtual = this.nota.xCplDest
		this.cartaDeCorrecao.complementoDestCorrigido = this.nota.xCplDest
		this.cartaDeCorrecao.razaoSocialDoDestAtual = this.nota.nomeDest
		this.cartaDeCorrecao.razaoSocialDoDestCorrigido = this.nota.nomeDest

		this.cartaDeCorrecao.enderecoEmit = this.nota.lgrEmit
		this.cartaDeCorrecao.nroEmitAtual = this.nota.nroEmit
		this.cartaDeCorrecao.nroEmitCorrigido = this.nota.nroEmit
		this.cartaDeCorrecao.complementoEmitAtual = this.nota.xCplEmit
		this.cartaDeCorrecao.complementoEmitCorrigido = this.nota.xCplEmit
		this.cartaDeCorrecao.razaoSocialDoEmitAtual = this.nota.nomeEmit
		this.cartaDeCorrecao.razaoSocialDoEmitCorrigido = this.nota.nomeEmit

		this.cartaDeCorrecao.informacoesAdicionaisAtual = this.nota.informacoesAdicionais
		this.cartaDeCorrecao.informacoesAdicionaisCorrigido = this.nota.informacoesAdicionais	
		this.cartaDeCorrecao.possuiFrete = this.nota.possuiFrete
		this.cartaDeCorrecao.volumeAtual = this.nota.volume
		this.cartaDeCorrecao.volumeCorrigido = this.nota.volume
		this.cartaDeCorrecao.especieAtual = this.nota.especie
		this.cartaDeCorrecao.especieCorrigido = this.nota.especie
		this.cartaDeCorrecao.pesoBrutoAtual = this.nota.pesoBruto
		this.cartaDeCorrecao.pesoBrutoCorrigido = this.nota.pesoBruto
		this.cartaDeCorrecao.pesoLiquidoAtual = this.nota.pesoLiquido
		this.cartaDeCorrecao.pesoLiquidoCorrigido = this.nota.pesoLiquido
		this.cartaDeCorrecao.chaveNFe = notaFiltrada.chNFe

		if(!venda || !venda.pontoDeVenda || !venda.pontoDeVenda.loja.id) throw Error("A venda ou a loja está indefinida (NULL)")

		this.cartaDeCorrecao.lojaId = venda?.pontoDeVenda?.loja.id  || ''
		
		this.mostra = true
	}

	async salvar(cartaDeCorrecao: CartaDeCorrecao) {
		try{
			this.salvando = true
			await this.cartaDeCorrecaoUseCase.criarCartaDeCorrecao({
				...cartaDeCorrecao,
				itens: this.itens,
			})
		} catch(e) {
			AlertModule.setError(e)
			this.salvando = false
			this.esconder()
			this.$emit('buscar')
		} finally {
			this.salvando = false
			this.esconder()
			this.$emit('buscar')
		}
		
	}


	get itens(): ItemDaCartaDeCorrecao[] {
		if (!this.nota) return [];
		
		return this.nota.itens.map(item => ({
			codigoProduto: item.codigoProduto,
			cfopAtual: item.cfop,
			cfopCorrigido: item.cfop,
			nomeProdutoAtual: item.nomeProduto,
			nomeProdutoCorrigido: item.nomeProduto,
			ncmAtual: item.ncm,
			ncmCorrigido: item.ncm,
			cstIcmsAtual: item.cstIcms,
			cstIcmsCorrigido: item.cstIcms,
			cstPisAtual: item.cstPis,
			cstPisCorrigido: item.cstPis,
			cstCofinsAtual: item.cstCofins,
			cstCofinsCorrigido: item.cstCofins,
		}));
	}

}


function criarNovaCartaDeCorrecao(): CartaDeCorrecao {
	return {
		id: '',
		enderecoDest: '',
		nroDestAtual: '',
		nroDestCorrigido: '',
		complementoDestAtual: '',
		complementoDestCorrigido: '',
		razaoSocialDoDestAtual: '',
		razaoSocialDoDestCorrigido: '',
		enderecoEmit: '',
		nroEmitAtual: '',
		nroEmitCorrigido: '',
		complementoEmitAtual: '',
		complementoEmitCorrigido: '',
		razaoSocialDoEmitAtual: '',
		razaoSocialDoEmitCorrigido: '',
		informacoesAdicionaisAtual: '',
		informacoesAdicionaisCorrigido: '',
		volumeAtual: 0,
		volumeCorrigido: 0,
		especieAtual: '',
		especieCorrigido: '',
		pesoBrutoAtual: 0,
		pesoBrutoCorrigido: 0,
		pesoLiquidoAtual: 0,
		pesoLiquidoCorrigido: 0,
		possuiFrete: false,
		itens: [],
		chaveNFe: '',
		lojaId: '',
		status: '',
		sequencial: 0,
		descricaoDaCorrecao: '',
		motivoDeRetornoEvento: '',
		urlDoXml: '',
	}
}
