var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900","scrollable":""},model:{value:(_vm.mostra),callback:function ($$v) {_vm.mostra=$$v},expression:"mostra"}},[_c('v-card',[_c('v-card-title',[_vm._v("Cartas de Correção")]),_c('v-container',[_c('DataTableDeCrud',{attrs:{"hideDelete":"","hideUpdate":"","hideDetail":false,"headers":_vm.header,"loading":_vm.loading,"items":_vm.cartasFormatadas,"options":_vm.paginacao,"server-items-length":_vm.totalRegistros,"tituloParaCriar":"Adicionar nova carta de correção","footer-props":{ itemsPerPageOptions: [ 5, 10, 15, 30, 50 ] }},on:{"update:options":function($event){_vm.paginacao=$event},"create":function () { return _vm.mostrarCriacaoDaCartaDeCorrecao(); }},scopedSlots:_vm._u([{key:"item.descricaoDaCorrecao",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"descricao"},on),[_vm._v(" "+_vm._s(item.descricaoDaCorrecao)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.descricaoDaCorrecao))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('Confirmacao',{attrs:{"width":"325","titulo":"Deseja imprimir carta de correção?","subtitulo":"Aviso: A carta gerada não possui valor fiscal","opcaoPadrao":"Não"},on:{"confirmar":function () { return _vm.imprimirNotaSemValorFiscal(item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":_vm.imprimirCarta.includes(item.id)}},Object.assign({}, dialog, tooltip)),[_vm._v(" "+_vm._s(_vm.imprimirCarta.includes(item.id) ? 'mdi-loading mdi-spin' : 'mdi-printer-outline')+" ")])]}}],null,true)},[_vm._v(" Imprimir Carta ")])]}}],null,true)})]}}],null,true),model:{value:(_vm.notasSelecionadas),callback:function ($$v) {_vm.notasSelecionadas=$$v},expression:"notasSelecionadas"}})],1)],1),_c('DialogoDeCriacaoDaCartaDeCorrecao',{ref:"dialogoDeCriacaoDaCartaDeCorrecao",on:{"buscar":_vm.buscarCartas}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }