










































































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { FindPerfilUseCase, FindUsuarioUseCase, LoginUseCase } from '@/usecases'
import { TokenJwt, Credenciais, Desconto, Perfil, ItemDaVenda } from '@/models'
import { obrigatorio } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { TipoDeRegraDePermissao } from '@/models/RegraDeNegocio'
import { perfilTemRegraDeNegocio } from '@/shareds/permissoes-shareds'
import { VendaModule } from '@/store/vuex/venda/VendaStore'

function gerarCredenciais(): Credenciais {
	return {
		login: '',
		senha: '',
	}
}

@Component
export default class DialogoDeAutenticacao extends Vue {
	@Ref() formDialogoAutenticacao!: HTMLFormElement
	@Ref() campoDeLogin!: HTMLInputElement

	obrigatorio = obrigatorio

	carregando = false
	mostra = false
	mostraErro = false
	regra: TipoDeRegraDePermissao | null = null
	errorMessage = ''
	persistent = false
	validar = true
	autenticacaoPadrao = true
	desconto: Desconto | null = null
	itemDaVenda: ItemDaVenda | null = null



	credenciais = gerarCredenciais()
	resolveDialogo: ((value: boolean) => void) | null = null

	get autenticacaoGeral() {
		return UserLoginStore.autenticando
	}

	async mostrar(
		lojaId?: string | undefined,
		regra?: TipoDeRegraDePermissao,
		desconto?: Desconto,
		item?: ItemDaVenda,
		persistent = false,
		validar = true,
		autenticacaoPadrao?: boolean,
	): Promise<boolean> {
		this.desconto = desconto || null
		this.itemDaVenda = item || null
		if (regra && UserLoginStore.permiteRegraDeNegocio(regra) && !desconto) return true
		
		this.mostra = true
		this.regra = regra || null
		this.persistent = persistent
		this.credenciais.lojaId = lojaId
		this.validar = validar
		
		this.autenticacaoPadrao = autenticacaoPadrao ?? true

		setTimeout(() => {
			this.campoDeLogin.focus()
			setTimeout(() => {
				this.formDialogoAutenticacao.resetValidation()
			})
		})
		return new Promise<boolean>(resolve => this.resolveDialogo = resolve)
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) {
			this.resolveDialogo && this.resolveDialogo(false)
			return
		}
		this.credenciais = {
			...this.credenciais,
			login: '',
			senha: '',
		}
	}

	fechar() {
		this.mostra = false
	}

	async autenticar(): Promise<void> {
		if (!this.formDialogoAutenticacao.validate()) return
		try {
			this.carregando = true
			const tokenJwt: TokenJwt = await new LoginUseCase().signIn(this.credenciais)
			if (!tokenJwt) throw new Error('Usuário ou senha inválidos')
			if (UserLoginStore.autenticando) await this.validarUsuarioLogado(tokenJwt)
			if (this.regra) await this.validarPermissaoDoLogin(tokenJwt, this.regra)
			this.resolveDialogo && this.resolveDialogo(true)
			this.mostra = false
			this.mostraErro = false
		} catch (error: any) {

			if(UserLoginStore.autenticando) {
				this.errorMessage = error.message;
				this.mostraErro = true
			} else AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async validarUsuarioLogado(tokenJwt: TokenJwt) {
		const usuarioLogado = await new FindUsuarioUseCase().usuarioLogado()
		const payload = JSON.parse(atob(tokenJwt.token.split('.')[1]))
		const idUsuario = payload.sub
		const usuario = await new FindUsuarioUseCase().findById(idUsuario)
		if (!usuario) throw new Error('Usuário não encontrado')
		if (usuario.id !== usuarioLogado.id) throw new Error('Credenciais diferem do usuário já logado.')
	}

	async validarPermissaoDoLogin(tokenJwt: TokenJwt, regra: TipoDeRegraDePermissao) {
		const payload = JSON.parse(atob(tokenJwt.token.split('.')[1]))
		const idUsuario = payload.sub
		const usuario = await new FindUsuarioUseCase().findById(idUsuario)
		if (!usuario) throw new Error('Usuário não encontrado')
		const perfil = await new FindPerfilUseCase().findById(usuario.perfil.id) || null

		if (!perfilTemRegraDeNegocio(perfil, regra)) throw new Error(`Usuário não tem permissão (${this.regra})`)

		this.itemDaVenda 
			? this.validarValorDescontoItemDaVenda(perfil, this.itemDaVenda)
			: this.validarValorDescontoCapaDaVenda(perfil)
	}

	validarValorDescontoItemDaVenda(perfil: Perfil, item: ItemDaVenda) {
		const venda = VendaModule.vendaAtual
		if (venda && this.desconto && this.desconto.valor > 0) {
			if(perfil.permissoes.includes('pode-conceder-desconto-por-item')) {
				if(item.possuiDescontoPorTabela) {
					const porcentagemDeDescontoDoItem = this.desconto.isPercentual
						? this.desconto.valor
						: (this.desconto.valor / item.preco) *100
					
					const percentualDeDescontoMaximo = Math.max(item.percentualDescontoTabelaPreco, perfil.percentualMaximoDescontoPorItem)

					if(porcentagemDeDescontoDoItem > percentualDeDescontoMaximo && venda.pagamentos.length < 1) {
						throw new Error(item.percentualDescontoTabelaPreco > perfil.percentualMaximoDescontoPorItem 
							? `O desconto aplicado neste item (${porcentagemDeDescontoDoItem.toFixed(2)}%) 
								excede o limite máximo permitido pela tabela de preço (${item.percentualDescontoTabelaPreco.toFixed(2)}%).`
							: `Este item tem um desconto de ${porcentagemDeDescontoDoItem.toFixed(2)}%, mas você só pode conceder até
								${perfil.percentualMaximoDescontoPorItem.toFixed(2)}% de desconto por item. Ajuste o valor do desconto para não exceder o limite permitido.`)
					}
				} else {
					const valorDeDescontoDoItem = item.possuiDescontoPorTabela ? 0 : this.desconto.valor
					const porcentagemDeDescontoDoItem = this.desconto.isPercentual
						? valorDeDescontoDoItem
						: (this.desconto.valor / item.preco) *100

					if(porcentagemDeDescontoDoItem > perfil.percentualMaximoDescontoPorItem && venda.pagamentos.length < 1) {
						throw new Error(`Este item tem um desconto de ${porcentagemDeDescontoDoItem.toFixed(2)}%, mas você só pode conceder até
						${perfil.percentualMaximoDescontoPorItem.toFixed(2)}% de desconto por item. Ajuste o valor do desconto para não exceder o limite permitido.`)
					}
				}
			} else {
				throw new Error('Usuário não tem permissão para conceder desconto por item')
			}
		}
	}

	validarValorDescontoCapaDaVenda(perfil: Perfil) {
		const venda = VendaModule.vendaAtual
		if (venda && this.desconto && this.desconto.valor > 0) {
			let valorTotalDeDescontoDosItens = 0;
			let valorBrutoDaVenda = 0;

			for(let i=0; venda.itens.length > i; i++) {
				const item = venda.itens[i]
				
				if(!item.possuiDescontoPorTabela && item.isCompra && this.desconto.valor > 0) {
					if(item.desconto.isPercentual) {
						const retiraValorDaPorcentagem = item.preco * (item.desconto.valor / 100);
						valorTotalDeDescontoDosItens = valorTotalDeDescontoDosItens + retiraValorDaPorcentagem;
					} else {
						valorTotalDeDescontoDosItens = valorTotalDeDescontoDosItens + item.desconto.valor;
					}
				}
				if(item.possuiDescontoPorTabela) {
					const descontoDaTabelaDePreco = (venda.itens[i].preco * (venda.itens[i].desconto.valor / 100))
					valorBrutoDaVenda = valorBrutoDaVenda + (venda.itens[i].preco - descontoDaTabelaDePreco)
				} else {
					valorBrutoDaVenda = valorBrutoDaVenda + (venda.itens[i].preco)
				}
			}

			const porcentagemDeDescontoDaCapa = this.desconto.isPercentual
				? this.desconto.valor
				: (this.desconto.valor / valorBrutoDaVenda) *100

			if (porcentagemDeDescontoDaCapa > perfil.percentualMaximoDesconto && venda.pagamentos.length < 1) {
				throw new Error(`O desconto aplicado é de ${porcentagemDeDescontoDaCapa.toFixed(2)}%, 
					mas o limite permitido para a capa da venda é de ${perfil.percentualMaximoDesconto.toFixed(2)}%`)
			}
		}
	}
}
