























































































import { Cashback } from '@/models/Cashback'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { CancelarCashbackUseCase } from '@/usecases/cliente/CancelarCashbackUseCase'
import { FindCashbackUseCase } from '@/usecases/cliente/FindCashbackUseCase'
import axios, { CancelTokenSource } from 'axios'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'
import Confirmacao from '../ui/Confirmacao.vue'
import DataTablePaginado from '../ui/data-tables/DataTablePaginado.vue'
import ListagemDeItemCashback from './ListagemDeItemCashback.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

@Component({
	components: {
		Confirmacao,
		ListagemDeItemCashback,
	},
})
export default class ListagemDeCashback extends Vue {
	@Prop({ type: String }) clienteId!: string
	@Prop({ type: String }) lojaId!: string
	@Prop({ type: Boolean, default: true }) hideDetail!: boolean

	@Ref() dataTable!: DataTablePaginado
	@Ref() dialogoDeListagemDeItemCashback!: ListagemDeItemCashback

	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	statusColor = statusColor

	findCashBackUseCase = new FindCashbackUseCase()
	cancelarCashbackUseCase = new CancelarCashbackUseCase()
	cancelToken: CancelTokenSource | null = null
	cashback: Cashback[] = []
	cancelando: string[] = []
	cashbackSelecionado: Cashback | null = null

	carregando = true
	loading = false
	mostrar = false

	totalRegistros = -1

	headers = [
		{ text: 'Status', value: 'statusDeCashback' },
		{ text: 'Data e hora', value: 'dataHora'},
		{ text: 'Identificador da venda', value: 'identificadorDaVenda'},
		{ text: 'Valor total creditado', value: 'valorTotalCreditado'},
		{ text: 'Id de autorização', value: 'autorizacaoId'},
		{ text: 'Loja Relacionada', value: 'loja'},
		{ text: 'Ações', value: 'actions', sortable: false, align: 'center' },
	]
	
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	@Watch('mostrar')
	created() {
		if(!this.mostrar) return 
		this.buscar()
	}

	@Watch('paginacao')
	async buscar () {
		if(!this.clienteId) return
		try {
			this.cancelToken = axios.CancelToken.source()

			const params = {
				page: this.paginacao.page - 1,
				clienteId: this.clienteId || undefined,
				size: this.paginacao.itemsPerPage,
				lojaId: this.lojaId,
			}
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const pagina = await this.findCashBackUseCase.findCashBackPorCliente(params, axiosConfig)
			this.cashback = pagina.content
			this.totalRegistros = pagina.totalElements
			this.carregando = false
		} catch (error: any) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
			this.carregando = false
		}
	}

	async cancelarCashback(cashback: Cashback) {
		this.loading = true
		try {
			const cashbackAtualizado = await this.cancelarCashbackUseCase.cancelar(cashback.id)
			this.cashback.map(cash =>  {
				if(cash.id === cashbackAtualizado.id) 
					cash.statusDeCashback = cashbackAtualizado.statusDeCashback
			})
			this.loading = false
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	get listaDeCashbackFormatada() {
		return this.cashback.map(cashbackFormatado => ({
			...cashbackFormatado,	
			dataHora: dateTimeToPtBrFormat(cashbackFormatado.dataHora),
			autorizacaoId: cashbackFormatado.autorizacaoId && cashbackFormatado.autorizacaoId.length > 0
				? cashbackFormatado.autorizacaoId : '-', 
		}))
	}


	temPermissao() {
		const permissoes = UserLoginStore.perfil?.permissoes
		if(!permissoes) return false
		for (const permissao of permissoes) {
			if (permissao.includes("deletar/cashback")) {
				return true
			}
		}
		return false
	}

	irPara(Identificador: string, isPedido: boolean) {
		const filtro = {
			busca: '',
			lojaId: '',
			datas: [null, null],
			horas: [null, null],
			cliente: null,
			pdvId: '',
			numeroDaNota: '',
			serieFiscal: '',
			exibeVendasComErros: false,
			ambientes: ['Homologação', 'Produção'],
			identificador: Identificador,
			identificadorExterno: '',
			vendasComOrcamento: false,
			vendasComPagamentoCancelado: false,
			origem: null,
		}
		localStorage.setItem('FILTRO_DE_VENDAS', JSON.stringify(filtro));
		if(isPedido){
			window.open(`/pedidos`, '_blank');
		}else{
			window.open(`/vendas`, '_blank');
		}
	}
}
export function statusColor(status: string) {
	if(status === 'Concluido') return 'success--text font-weight-bold'
	if(status === 'Pendente') return 'warning--text font-weight-bold'
	if(status === 'Cancelado') return 'error--text font-weight-bold'
}
