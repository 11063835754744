import { Cliente, Duracao, FiltroDeAniversariante, FormCliente, Pessoa, TipoDeCliente, UnidadeDeTempo, Voucher } from '@/models'
import moment from 'moment'

export function criarCliente() {
	const cliente: FormCliente = {
		cnpjOuCpf: '',
		razaoSocialOuNome: '',
		inscricaoEstadual: 'ISENTO',
		inscricaoSuframa: '',
		email: '',
		telefones: [],
		endereco: {
			id: null,
			rua: '',
			numero: '',
			cep: '',
			bairro: '',
			complemento: '',
			codigoIBGE: '',
			cidade: '',
			uf: '',
			codigoPais: '',
			nomePais: '',
		},
		id: '',
		idEstrangeiro: '',
		dataExpiracaoPlano: '',
		tipoDeCliente: null,
		tipoDeClienteNome: '',
		dataDeExpiracaoDoPlano: null,
		dataDePagamentoDoPlano: null,
		dataNascimento: null,
		limiteCredito: 0,
		limiteDoCrediario: 0,
		valorPendenteEmCrediario: 0,
		representanteComercial: {
			id: '',
			nome: '',
			email: null,
			cpf: null,
			login: '',
			ativo: true,
			perfil: {
				id: '',
				nome: '',
				permissoes: [],
				gruposEconomicos: [],
				percentualMaximoDesconto: 0,
				tempoParaLogoff: '',
				percentualMaximoDescontoPorItem: 0,
				tempoLimiteDeInatividade: '',
				telaInicial: '',
			},
			vendedor: false,
			criaFaq: true,
			autoLoginAoAbrirCaixa: false,
			codigoExterno: undefined,
		},
		lojas: [],
		observacao: '',
		createdAt: '',
		gruposEconomicos: [],
		genero: null,
		profissao: null,
		enderecosAdicionais: [],
		nomeDeTratamento: '',
	}
	return cliente
}

export function criarTipoDeCliente(): TipoDeCliente {
	return {
		id: '',
		nome: '',
		expiraEm: criarExpiraEm(),
		urlExterna: '',
		produtoDoPlano: null,
		aplicarNasEtiquetas: false,
	}
}

export function criarExpiraEm(): Duracao {
	return {
		quantidade: 1,
		unidade: 'months',
	}
}

export const listasDeUnidadesDeTempo: {
	text: string
	value: UnidadeDeTempo
}[] = [
	{
		text: 'Dia',
		value: 'days',
	},
	{
		text: 'Semana',
		value: 'weeks',
	},
	{
		text: 'Mês',
		value: 'months',
	},
	{
		text: 'Ano',
		value: 'years',
	},
]

export function voucherEstaExpirado(voucher: Voucher) {
	return moment(voucher.expiracao).isBefore()
}

export function extrairCnpjOuCpfDoCliente(cliente: Cliente | string | null): string | null {
	return extrairCnpjOuCpfDaPessoa(cliente)
}

export function extrairCnpjOuCpfDaPessoa(pessoa: Pessoa | string | null): string | null {
	if (pessoa === null) return null
	return typeof pessoa === 'object'
		? pessoa.cnpjOuCpf
		: pessoa
}

export function limparFiltroDosCamposDeAniversariante(): FiltroDeAniversariante {
	return {
		periodo: 'dia',
		cliente: null,
		lojaId: '',
		genero: '',
		profissao: null,
		somenteComCashback: false,
	}
}
