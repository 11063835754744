







































































































































import DatePicker from '@/components/ui/DatePicker.vue'
import { formatarMoeda } from '@/shareds/formatadores'
import { FormaDePagamento, PontoDeVendaComTurno, Titulo } from '@/models'
import { BaixaDePagamentoForm } from '@/models/financeiro/BaixaDePagamento'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { CreateBaixaDePagamentoUseCase, FindTituloUseCase, SaveTituloUseCase } from '@/usecases'
import { Vue, Component, Watch, Ref, Prop } from 'vue-property-decorator'
import moment from 'moment-timezone'
import { imprimirComprovanteDeRecebivel } from '@/shareds/venda-shareds'
import { FindPdvUseCase } from '@/usecases/pdv/FindPdvUseCase'
import { obrigatorio } from '@/shareds/regras-de-form'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import Decimal from 'decimal.js'
import { calcularDataAposCarencia } from '@/shareds/titulo-shareds'

@Component({
	components: {
		DatePicker,
	},
})
export default class DialogoDeEdicaoDeBaixasDePagamento extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop({ type: Boolean, default: false }) retornoCrediario!: boolean
	@Prop({ type: Boolean, default: false }) contasAPagar!: boolean
	@Ref() menu!: {
		save: (value?: any) => void
	}
	
	obrigatorio = obrigatorio
	formatarMoeda = formatarMoeda
	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	data = ''
    horas = ''
	buscando = false
	salvando = false
	mostra = false
	menu2 = false
	time = null
	titulo: Titulo | null = null
	valorFaltante: number | null = null
	valorFaltanteAtualizado: number | null = null
	formaDePagamento = ''
	turnoDeVendaId: string | null = null

	dataPagamento: string | null = null

	createBaixaDePagamentoUseCase = new CreateBaixaDePagamentoUseCase()
	findPdvUseCase = new FindPdvUseCase()
	saveTituloUseCase = new SaveTituloUseCase()
	findTituloUseCase = new FindTituloUseCase()

	pdvOptions: PontoDeVendaComTurno[] = []

	baixaDePagamento: BaixaDePagamentoForm = {
		titulo: null,
		transactionId: '',
		valorPagamento: 0,
		valorTaxa: 0,
		dataHoraPagamento: '',
		isTaxa: false,
		isIntegracao: false,
		formaDePagamento: null,
		valorDesconto: 0,
		turnoDeVendaId: null,
	}

	opcoesDeFormaDePagamento: FormaDePagamento[] = [
		'Dinheiro',
		'Cartão',
		'Pix',
	]

	opcoesDeFormaDePagamentoCaixa: FormaDePagamento[] = [
		'Dinheiro',
		'Cartão',
		'Pix',
	]

	get permitirAlterarDataBaixa() {
		return UserLoginStore.permiteRegraDeNegocio('permitir-alteracao-data-pagamento')
	}

	@Watch('mostra', { immediate: true })
	changeDate() {
		if(!this.mostra) return

		this.data = new Date().toISOString().split('T')[0] || ''
		this.horas = new Date().toLocaleTimeString('pt-BR')
	}

	@Watch('data', { deep: true, immediate: true })
	@Watch('baixaDePagamento', { deep: true, immediate: true })
	calcularValorFaltante() {
		if(this.valorFaltanteAtualizado === null || !this.titulo) return

		if (
			this.titulo.formaDePagamento === "CREDIARIO" &&
			this.titulo.fluxo === 'Entrada'
		) {
			const calcularJurosComMulta = this.titulo.loja?.configuracaoDaLoja?.calcularJurosComMulta || false
			const multaParaDescontar = calcularJurosComMulta ? (this.titulo.multa || 0) : 0 
			const valorLiquidoAtualizado = Number(((this.titulo.valorAtualizado || 0) - (this.titulo.juros || 0) - (this.titulo.multa || 0)).toFixed(2))
			const data = new Date(`${this.data}T${this.horas}`)
			const dataStr = this.titulo.dataDeVencimento
			const [ano, mes, dia] = dataStr.split('-').map(Number)
			const dataDeVencimento = new Date(ano, mes - 1, dia)
			const dataAposCarencia = calcularDataAposCarencia(this.titulo, dataDeVencimento)
			const diferencaMs = data.getTime() - dataAposCarencia.getTime()
			const dias = Math.floor(diferencaMs / (1000 * 60 * 60 * 24))
			const capital = new Decimal(valorLiquidoAtualizado).add(new Decimal(multaParaDescontar))
			const taxa = new Decimal(this.titulo.porcentagemDeJuros || 0).div(100)
			const expoente = new Decimal(dias)
			const dataPagamento = new Date(data);
			const dataAposCarenciaSemHora = new Date(dataAposCarencia)

			dataPagamento.setHours(0, 0, 0, 0)
			dataAposCarenciaSemHora.setHours(0, 0, 0, 0)
			
			if(this.titulo.jurosComposto) {
				const montante = capital.mul(Decimal.pow(1 + taxa.toNumber(), expoente.toNumber()))
				const montanteArrendondado = calcularJurosComMulta 
					? Number(montante.toFixed(2))
					: Number(montante.toFixed(2)) + Number(this.titulo.multa)

				this.valorFaltante = Number(dataPagamento <= dataAposCarenciaSemHora 
					? valorLiquidoAtualizado 
					: montanteArrendondado)
				this.valorFaltanteAtualizado = Number((dataPagamento <= dataAposCarenciaSemHora 
					? valorLiquidoAtualizado 
					: montanteArrendondado) - (Number(this.baixaDePagamento.valorPagamento) + Number(this.baixaDePagamento.valorDesconto)))
			} else {
				const dataPagamento = new Date(data)
				const dataAposCarenciaSemHora = new Date(dataAposCarencia)
	
				const montante = capital.mul(taxa.mul(dias).add(1))
				const montanteArrendondado = calcularJurosComMulta 
					? Number(montante.toFixed(2))
					: Number(montante.toFixed(2)) + Number(this.titulo.multa)
				dataPagamento.setHours(0, 0, 0, 0);
				dataAposCarenciaSemHora.setHours(0, 0, 0, 0);

				this.valorFaltante = Number(dataPagamento <= dataAposCarenciaSemHora 
					? valorLiquidoAtualizado 
					: montanteArrendondado)

				this.valorFaltanteAtualizado = Number((dataPagamento <= dataAposCarenciaSemHora 
					? valorLiquidoAtualizado 
					: montanteArrendondado) - (Number(this.baixaDePagamento.valorPagamento) + Number(this.baixaDePagamento.valorDesconto)))
			}
		}
		
	}

	mostrarDialogo(titulo: Titulo | null, valorFaltante: number) {
		this.pdvOptions = []
		this.mostra = true
		this.titulo = titulo ? titulo : null
		this.valorFaltante = valorFaltante
		this.valorFaltanteAtualizado = valorFaltante

		this.preencherPdvAutomatico();
		this.buscarPdvAberto();
	}

	@Watch('baixaDePagamento.valorPagamento')
	validarDesconto() {
		const valorFaltante = this.valorFaltante

		if (valorFaltante === null) {
			return 'Valor faltante não definido'
		}

		const descontoNumerico = Number(this.baixaDePagamento.valorDesconto)
		const valorPagamentoNumerico = Number(this.baixaDePagamento.valorPagamento)
		const valorTaxaNumerico = Number(this.baixaDePagamento.valorTaxa)

		const soma = descontoNumerico + valorPagamentoNumerico + valorTaxaNumerico
		
		if (soma > valorFaltante) {
			return 'A soma do desconto, pagamento e taxa não pode ser maior que o valor faltante'
		}

		return true
	}
	
	preencherPdvAutomatico() {
		if (this.contasAPagar) return;
		const turnoDeVenda = JSON.parse(localStorage.getItem('TURNO_DE_VENDA_STORAGE') || 'null')

		if (turnoDeVenda && turnoDeVenda.pontoDeVenda && this.titulo && this.titulo.loja) {

			if (turnoDeVenda.pontoDeVenda.loja.id === this.titulo.loja.id) {
				const pdv = {
					turnoDeVendaId: turnoDeVenda.id,
					nomePontoDeVenda: turnoDeVenda.pontoDeVenda.nome,
				};

				if (!this.pdvOptions.some(p => p.turnoDeVendaId === pdv.turnoDeVendaId)) {
					this.pdvOptions = [pdv, ...this.pdvOptions];
				}

				if (!this.baixaDePagamento.turnoDeVendaId) {
					this.baixaDePagamento.turnoDeVendaId = pdv.turnoDeVendaId;
				}
			}
		}
	}

	async buscarPdvAberto() {
		if (!this.titulo || !this.titulo.loja) return;
		const pdvs = await this.findPdvUseCase.buscarPdvSemTurno(this.titulo.loja.id);
		this.pdvOptions = [...this.pdvOptions, ...pdvs];
	}

	async salvarBaixa() {
		if (!this.baixaDePagamento) return
		if (!this.titulo) return

		if (this.valorFaltante === null) {
			return AlertModule.setError('Valor faltante não pode ser nulo!')
		}

		if ((!this.baixaDePagamento.valorPagamento && !this.baixaDePagamento.valorDesconto) 
			|| (this.baixaDePagamento.valorPagamento <= 0 && this.baixaDePagamento.valorDesconto <=0)
		) {
			return AlertModule.setError('Valor de pagamento ou desconto é obrigatório!')
		}
		
		if (Number(this.baixaDePagamento.valorPagamento) + Number(this.baixaDePagamento.valorDesconto) + Number(this.baixaDePagamento.valorTaxa) > Number(this.valorFaltante)) {
			return AlertModule.setError('A soma do desconto, pagamento e taxa não pode ser maior que o valor faltante')
		}

		const valorPagamento = Number(this.baixaDePagamento.valorPagamento);
		const valorTaxa = Number(this.baixaDePagamento.valorTaxa);
		const totalPagamento = Math.round((valorPagamento + valorTaxa) * 100) / 100;
		const valorFaltanteArredondado = Math.round(this.valorFaltante * 100) / 100;

		if (totalPagamento > valorFaltanteArredondado) {
			return AlertModule.setError('Valor de pagamento acrescido da taxa não pode ser maior que o valor')
		}
		if ( this.baixaDePagamento.valorTaxa > this.valorFaltante) {
			return AlertModule.setError('Valor de taxa não pode ser maior que o valor faltante!')
		}
		if (!this.formaDePagamento) {
			return AlertModule.setError('Forma de pagamento é obrigatória!')
		}
		if (!this.baixaDePagamento.turnoDeVendaId && !this.contasAPagar) {
			return AlertModule.setError('Necessário Ponto De Venda')
		}
		
		try {
			this.salvando = true
			this.baixaDePagamento.titulo = this.titulo

			this.baixaDePagamento.formaDePagamento = this.formaDePagamento
			this.baixaDePagamento.dataHoraPagamento = `${this.data}T${this.horas}`

			const comprovanteDaBaixa = await this.createBaixaDePagamentoUseCase.create(this.baixaDePagamento)

			await imprimirComprovanteDeRecebivel(comprovanteDaBaixa)
			this.$emit('atualizar', this.baixaDePagamento)
			this.limparDados()
	
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
			this.mostra = false
			this.limparDados()
		}
	}

	limparDados() {
		this.mostra = false
		this.time = null
		this.dataPagamento = null
		this.data = ''
		this.horas = ''
		this.formaDePagamento = ''
		this.valorFaltante = null
		this.valorFaltanteAtualizado = null
		this.baixaDePagamento = {
			titulo: null,
			transactionId: '',
			valorPagamento: 0,
			valorTaxa: 0,
			dataHoraPagamento: '',
			isTaxa: false,
			isIntegracao: false,
			formaDePagamento: null,
			valorDesconto: 0,
			turnoDeVendaId: null,
		}
	}

	cancelar() {
		this.limparDados()
	}

	gerarDataHora(data?: string | null, hora?: string | null): string {
		const dateTimeString = `${data}T${hora}:00`;
		const localDateTime = moment(dateTimeString, 'YYYY-MM-DDTHH:mm:ss');
		const utcDateTime = localDateTime.utcOffset(0, true); // Define o offset para 0 (UTC)
		const utcDateTimeString = utcDateTime.toISOString();

		return utcDateTimeString;
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) return
		window.requestAnimationFrame(() => {
			this.form.resetValidation()
		})
	}
}
