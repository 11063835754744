var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.mostra),callback:function ($$v) {_vm.mostra=$$v},expression:"mostra"}},[(_vm.produto)?_c('v-card',[_c('v-card-title',{staticClass:"pb-0 flex-nowrap"},[_c('div',{staticStyle:{"word-break":"normal"}},[_vm._v(_vm._s(_vm.produto.nome))]),_c('v-btn',{staticClass:"justify-self-end ml-3",attrs:{"icon":""},on:{"click":function () { return (_vm.mostra = false); }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-breadcrumbs',{attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',[_c('div',{class:{
							'font-weight-bold':
								_vm.obterIndiceDoAtributo(item) === _vm.valoresSelecionados.length,
						},style:(_vm.obterIndiceDoAtributo(item) < _vm.valoresSelecionados.length
								? {
										cursor: 'pointer',
										textDecoration: 'underline',
									}
								: undefined),attrs:{"tabindex":_vm.obterIndiceDoAtributo(item) < _vm.valoresSelecionados.length
								? 0
								: undefined,"disabled":item.disabled},on:{"click":function () { return _vm.produto && _vm.resetarPara(item); },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () { return _vm.produto && _vm.resetarPara(item); }).apply(null, arguments)}}},[_vm._v(_vm._s(item.text))])])]}}],null,false,49463579)}),_c('v-card-text',[(
					_vm.valoresSelecionados.length !== _vm.produto.atributosProduto.length &&
						_vm.atributo
				)?[_c('v-row',_vm._l((_vm.atributo.valores),function(valor){return _c('v-col',{key:((_vm.atributo && _vm.atributo.atributo.nome) + "-" + valor),style:({
							flexBasis: _vm.$vuetify.breakpoint.xs ? '100%' : '25%',
						})},[_c('v-card',{ref:"cards",refInFor:true,staticClass:"align-center justify-center",attrs:{"dark":"","height":"120"},on:{"click":function () { return _vm.selecionarValor(valor, _vm.atributo.atributo.nome); },"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () { return _vm.selecionarValor(valor, _vm.atributo.atributo.nome); }).apply(null, arguments)}}},[_c('v-card-title',{staticClass:"align-center justify-center"},[_vm._v(" "+_vm._s(valor)+" ")]),(_vm.$route.fullPath === '/caixa')?_c('v-card-subtitle',{staticClass:"d-flex align-center justify-center mb-10"},[_vm._v(" ( "),_c('span',{staticStyle:{"color":"orange"}},[_vm._v(" "+_vm._s(_vm.quantidadeEmEstoque(valor, _vm.atributo.atributo.nome))+" PC ")]),_vm._v(" ) ")]):_vm._e()],1)],1)}),1)]:(_vm.buscando)?[_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"primary"}})]:(!_vm.temVariante)?[_c('div',{staticClass:"error--text"},[_vm._v("Produto não possui variante para os atributos selecionados ")]),_c('span',[_c('a',{ref:"revisar",staticStyle:{"text-decoration":"underline"},attrs:{"tabindex":"0"},domProps:{"textContent":_vm._s('Revisar atributos')},on:{"click":function($event){$event.preventDefault();return (function () { return (_vm.valoresSelecionados = [], _vm.atributosSelecionados = []); }).apply(null, arguments)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () { return (_vm.valoresSelecionados = [], _vm.atributosSelecionados = []); }).apply(null, arguments)}}})])]:_vm._e()],2)],1):_c('div',[_c('AppLoading')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }