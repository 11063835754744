


















































import { Vue, Component, PropSync, Ref, Prop, Watch } from 'vue-property-decorator'
import { FindProdutoUseCase } from '@/usecases'
import { Produto, TipoDeProduto, Page, FormProdutoSimples } from '@/models'
import type { Loja } from '@/models'
import DialogoDeSelecionarVariante from '@/components/produto/DialogoDeSelecionarVariante.vue'
import { Fragment } from 'vue-fragment'
import { displayNomeCompletoDoProduto } from '@/shareds/produto-shareds'
import BuscarProdutosFiltradosUseCase from '@/usecases/produto/BuscarProdutosFiltradosUseCase'
import { Pageable } from '@/models/Pageable'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import axios, { CancelTokenSource } from 'axios'
import DialogoDeAutenticacao from '@/views/application/venda/DialogoDeAutenticacao.vue'
import { VendaModule } from '@/store/vuex/venda/VendaStore'

@Component({
	components: {
		DialogoDeSelecionarVariante,
		Fragment,
		DialogoDeAutenticacao,
	},
})
export default class BuscaDeProdutoDropdown extends Vue {
	@Ref() seletorDeVariante!: DialogoDeSelecionarVariante
	@Ref() campo!: HTMLInputElement
	@PropSync('value', { type: [Object, String], default: null }) produto!: Produto | FormProdutoSimples | null
	@Prop({ type: Boolean, default: false }) requerFilho!: boolean
	@Prop({ type: Boolean, default: true }) reconsultarAposBusca!: boolean
	@Prop({ type: Object, default: () => ({}) }) filtros!:
		| {
			tipos: TipoDeProduto[]
		}
		| {}

	@Prop({ type: Array, default: () => [] }) filtroProdutos!: Produto[]
	@Prop({ type: Boolean, default: false }) usaLoja!: boolean
	@Prop() loja!: Loja

	displayNomeCompletoDoProduto = displayNomeCompletoDoProduto

	produtos: Produto[] = []
	findProdutoUseCase = new FindProdutoUseCase()
	pagina: Page<Produto> | null = null
	busca: string | null = null

	produtosCarregados = 0
	carregouTodosOsProdutos = false
	carregando = false
	cancelToken: CancelTokenSource | null = null
	preBusca = ''

	created() {
		if (!this.reconsultarAposBusca) {
			this.buscar({ page: 0, size: 5 })
		}
	}

	focus() {
		this.campo.focus()
	}

	blur() {
		this.campo.blur()
	}

	buscando = false
	errors: string[] = []

	// Código legado
	// async buscarProduto() {
	// 	if (!this.busca) return
	// 	if (this.cancelToken) {
	// 		this.cancelToken.cancel()
	// 		this.carregando = false
	// 		this.produtos = []
	// 	}

	// 	try {
	// 		this.errors = []
	// 		this.buscando = true
	// 		this.$emit('carregando', this.buscando)

	// 		const findProdutoUseCase = new FindProdutoUseCase()

	// 		const produto = this.usaLoja
	// 			? await findProdutoUseCase.findProdutoComEstoque(this.busca, this.loja.id)
	// 			: (await findProdutoUseCase.find(this.busca)).content[0]

	// 		const tipos = ['Padrão', 'Composto', 'Com Variantes', 'Variante']
	// 		if (!tipos.includes(produto?.tipo)) throw new Error('Produto não encontrado')
	// 		if (this.cancelToken) this.cancelToken.cancel()
	// 		this.produtos = []
	// 		this.produto = produto
	// 		this.selecionarProduto(produto as Produto)
	// 	} catch (error: any) {
	// 		this.errors = (error as AxiosError).response?.data
	// 		this.$emit('input', null)
	// 	} finally {
	// 		this.busca = ''
	// 		this.buscando = false
	// 		setTimeout(() => {
	// 			this.campo.focus()
	// 		})
	// 	}
	// }

	async buscar(paginavel: Pageable = {}) {
		if (!this.busca) {
			this.produtos = []
			return
		}
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			const lojaId = this.$route.fullPath === '/caixa' ? VendaModule.lojaDaVenda?.id || VendaModule.lojaDaVenda : undefined

			const filtros = {
				...this.filtros,
				nome: this.busca || undefined,
				identificador: this.busca || undefined,
				tipos: this.filtros['tipos']
					? [...this.filtros['tipos']]
					: ['Padrão', 'Com Variantes', 'Composto', 'Variante'] as TipoDeProduto[],
				lojaId: lojaId,
			}

			this.pagina = await BuscarProdutosFiltradosUseCase({
				filtros,
				pageable: {
					page: paginavel.page,
					size: paginavel.size,
				},
				axiosConfig: {
					cancelToken: this.cancelToken.token,
				},
			})
			if (!this.busca) {
				this.pagina.content.forEach(produto => {
					this.produtos.push(produto)
				})
			} else {
				this.produtos = this.pagina.content
			}

			if (this.produtos.length === 1) {
				this.selecionarItem(this.produtos[0])
			}

			this.produtosCarregados += this.pagina.numberOfElements
			this.carregouTodosOsProdutos = this.produtosCarregados >= this.pagina.totalElements
			this.carregando = false
		} catch (error: any) {
			if (axios.isCancel(error)) return
			this.carregando = false
			AlertModule.setError(error)
		}
	}

	subtituloProduto(produto: Produto) {
		switch (produto.tipo) {
			case 'Com Variantes':
				return `Produto Com Variantes`
			case 'Variante':
				return `${[produto.eans[0], produto.sku].filter(valor => !!valor).join(' / ')}`
			default:
				return produto.sku
		}
	}

	@Watch('busca')
	onChangeBusca(busca: string, buscaAnterior: string) {
		if (this.busca && this.produto && this.busca === displayNomeCompletoDoProduto(this.produto)) return
		if (!busca && buscaAnterior && !this.reconsultarAposBusca) return
		this.buscar({ page: 0, size: 5 })
	}

	realizarBusca(busca: string | Produto) {
		if (typeof busca === 'string') {
			this.busca = busca
		}
	}

	selecionarItem(valor) {
		this.selecionarProduto(valor)
		this.produtos = []
		this.preBusca = ''
		this.busca = null
	}

	async selecionarProduto(produto: Produto) {
		if(!produto) return 
		this.carregando = true
		try {
			
			if(produto.tipo === 'Com Variantes' && this.requerFilho) {
				this.seletorDeVariante.abrir(new Promise((r) => r(produto)))
			} else {
				await this.findProdutoUseCase.validarProdutoDesativado(produto.id)
				this.$emit('input', produto)
			} 
			this.carregando = false
		} catch(e) {
			AlertModule.setError(e)
			this.carregando = false
		}
	}


	get itens() {
		if (this.buscando) return []
		if (!this.busca) return this.produto ? [this.produto] : []
		if (this.carregando) return []
		const produtos = this.filtroProdutos
			? this.produtos.filter(({ id }) => !this.filtroProdutos.some(produto => produto.id === id))
			: this.produtos
		const existe =
			!!this.produto || produtos.some(({ id }) => id === this.produto?.id)
		return !existe ? produtos : [this.produto, ...produtos]
	}

	get noDataMessage() {
		if (this.buscando) return null
		if (this.carregando) {
			return 'Carregando...'
		}
		if (!this.busca) {
			return 'Digite para consultar...'
		}
		return '$vuetify.noDataText'
	}
}
