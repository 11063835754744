var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-checkbox',{attrs:{"label":"Expirados"},model:{value:(_vm.expirado),callback:function ($$v) {_vm.expirado=$$v},expression:"expirado"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Selecione o status...","items":['Voucher','Cashback'],"multiple":""},model:{value:(_vm.tipos),callback:function ($$v) {_vm.tipos=$$v},expression:"tipos"}})],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.carregando,"disabled":_vm.carregando},on:{"click":_vm.buscar}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" buscar ")],1)],1)],1),_c('v-data-table',_vm._g(_vm._b({attrs:{"items":_vm.pagina,"headers":_vm.headers,"loading":_vm.carregando,"server-items-length":_vm.totalRegistros,"options":_vm.paginacao,"manterPaginas":"","must-sort":"","hideDelete":"false"},on:{"update:options":function($event){_vm.paginacao=$event}},scopedSlots:_vm._u([{key:"item.dataHoraGeracao",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.dateTimeToPtBrFormat(value))+" ")]}},{key:"item.expiracao",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.dateTimeToPtBrFormat(value))+" ")]}},{key:"item.valor",fn:function(ref){
var value = ref.value;
return [_vm._v(" R$ "+_vm._s(_vm.formatarMoeda(value))+" ")]}},{key:"item.grupoEconomico",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? value.descricao : 'Sem Grupo Econômico')+" ")]}},{key:"item.situacao",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.voucherEstaExpirado(item)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{
							'text-decoration-line-through': _vm.voucherEstaExpirado(item),
						}},on),[_vm._v(" "+_vm._s(value)+" ")])]}}],null,true)},[_c('span',[_vm._v("Expirado")])])]}},{key:"item.acoes",fn:function(ref){
						var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap justify-center"},[_c('DialogoDeDetalhesDoCashback',{attrs:{"cashbackId":item.cashbackId},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var dialog = ref.on;
return [(item.tipo === 'Cashback')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var tooltip = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},Object.assign({}, tooltip, dialog)),[_vm._v(" mdi-eye ")])]}}],null,true)},[_vm._v(" Detalhes ")]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"ml-2 mr-2"},[(item.situacao != 'Inutilizado')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
return [(item.situacao !== 'Utilizado' && !_vm.voucherEstaExpirado(item))?_c('v-icon',_vm._g({attrs:{"small":"","color":item.situacao === 'Inutilizado' ? 'grey darken-1' : 'error'},on:{"click":function () { return _vm.exibeConfirmacao(item); }}},on),[_vm._v(" mdi-minus-circle ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Inutilizar")])]):_vm._e()],1),_c('Confirmacao',{attrs:{"width":"325","titulo":"Deseja imprimir comprovante voucher?"},on:{"confirmar":function () { return _vm.imprimirVoucherCliente(item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var tooltip = ref.on;
return [(item.tipo === 'Voucher' && item.venda != null)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":_vm.imprimirVoucher.includes(item.id)}},Object.assign({}, dialog, tooltip)),[_vm._v(" "+_vm._s(_vm.imprimirVoucher.includes(item.id) ? 'mdi-loading mdi-spin' : 'mdi-printer-outline')+" ")]):_vm._e()]}}],null,true)},[_vm._v(" Imprimir voucher ")])]}}],null,true)})],1)]}},{key:"body.append",fn:function(ref){
						var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex justify-space-around"},[_c('strong',[_vm._v(" Valor total: "+_vm._s(_vm.totalVoucher > 0 ? _vm.totalVoucher.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0..toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")])])])]),(_vm.podeCriarVoucher)?_c('tr',[_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-btn',{attrs:{"text":"","color":"success","block":"","data-cy":"novoVoucher"},on:{"click":_vm.novoVoucher}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Novo Voucher")],1)],1)]):_vm._e()]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners)),_c('DialogoVoucherAvulso',{ref:"dialogoDeVoucher",attrs:{"clienteId":_vm.clienteId},on:{"salvarVoucher":_vm.salvarVoucher}}),_c('Confirmacao',{ref:"confirmacaoDeInativarVoucher",attrs:{"titulo":("Inativar voucher de R$ " + (_vm.selecionado && _vm.formatarMoeda(_vm.selecionado.valor)) + "?"),"subtitulo":_vm.obterSubtituloDeInativacao(),"width":"400","opcaoPadrao":"Não"},on:{"confirmar":function () { return _vm.inutilizarVoucher(_vm.selecionado); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }