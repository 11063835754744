import { Inject } from "inversify-props"
import type { BaixaDePagamentoServiceAdapter } from "./adapter"
import { BaixaDePagamento } from "@/models/financeiro/BaixaDePagamento"

export class UpdateBaixaDePagamentoUseCase {
	@Inject('BaixaDePagamentoServiceAdapter')
	private baixaDePagamentoServiceAdapter!: BaixaDePagamentoServiceAdapter

	update = async (id: string, formaDePagamento: string): Promise<any> =>
		await this.baixaDePagamentoServiceAdapter.update(id, formaDePagamento)

	updateBaixaDePagamento = async (baixa: BaixaDePagamento): Promise<void> =>
		await this.baixaDePagamentoServiceAdapter.updateBaixaDePagamento(baixa)
}